import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { RouteType, ChildRouteType } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { PermissionsService, UrlService } from 'lib';

import type { FeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags.types';

import Icon from 'components/icon';
import { WithFeatureFlag } from 'components/with-feature-flag/with-feature-flag';

import * as Styles from './nav-link.styles';
import * as Types from './nav-link.types';

class NavLink extends React.Component<Types.NavLinkProps> {
  private onClick = (
    event: React.MouseEvent,
    route: RouteType | ChildRouteType,
    isSubNav: boolean,
    section: string,
    url: string
  ): void => {
    event.preventDefault();

    if (!route.isExternal) {
      this.props.onRouteChange(route, isSubNav, section, url);
    } else if (route.isExternal) {
      const win = window.open(url, '_blank');
      win?.focus();
    }
  };

  private isSectionOpen = (): boolean => {
    return this.props.location.split('/')[1].toLowerCase() === this.props.route.identifier;
  };

  private shouldDisplayRoute = (): boolean => {
    return (PermissionsService.isInternalRole()
      || this.props.route.requiredPlanFeatures?.every(feature => this.props.merchantStore!.merchant?.plan?.features?.includes(feature)))
      && !this.props.route?.isFeatureFlagDisabled?.(this.props.merchantStore!.merchant);
  };

  private renderChildItems = (flags: FeatureFlags): ReactNode => {
    const isOpen = this.isSectionOpen();

    if (!this.props.route.children || this.props.navigationStore!.isCollapsed) return null;

    return (
      <Styles.Accordion
        isSectionOpen={isOpen}
        aria-expanded={isOpen}
      >
        {
          this.props.route.children!
            .filter(child => !this.props.merchantStore!.merchant || child.id !== 'orders-list-peak' || this.props.merchantStore!.merchant?.currency === 'USD')
            .map(child => {
              if (child.isHidden) return null;

              const isDisabled = (): boolean => {
                if (typeof child.isDisabled === 'function') {
                  return child.isDisabled(this.props.merchantStore!.merchant, flags);
                }

                return child.isDisabled;
              };

              if (isDisabled()) return null;

              return (
                <Styles.AccordionItem
                  key={child.url}
                  title={child.url}
                >
                  <Styles.NavLinkChild
                    as="a"
                    id={child.id}
                    href={child.url}
                    title={child.isDisabled ? 'Coming soon' : child.name}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => this.onClick(e, child, true, this.props.route.name, child.url)}
                    css={css`
                      ${(child.url.startsWith('/promoted/upcoming') && this.props.location.startsWith('/promoted/upcoming'))
                        && Styles.NavLinkActive}
                      ${UrlService.isExactMatch(child.url, this.props.location) && Styles.NavLinkActive}
                    `}
                  >
                    {child.name}
                  </Styles.NavLinkChild>
                </Styles.AccordionItem>
              );
            })
        }
      </Styles.Accordion>
    );
  };

  private buildRootURL = ({
    url,
    flags
  }: {
    url: RouteType['url'];
    flags: FeatureFlags;
  }): string => {
    if (typeof url === 'function') {
      return url({
        merchant: this.props.merchantStore!.merchant,
        flags: flags
      });
    }

    return url;
  }

  render(): React.ReactNode {
    const { route, location } = this.props;
    const { url, isDisabled, id } = route;
    const isCollapsed = this.props.navigationStore!.isCollapsed;

    if (!this.shouldDisplayRoute()) return null;

    return (
      <WithFeatureFlag
        render={({
          flags
        }) => {
          const rootUrl = this.buildRootURL({ url, flags });

          if (!this.props.merchantStore!.merchant?.promotedActive) {
            if (route.identifier === 'promoted') return null;
          }

          if (this.props.merchantStore!.merchant?.currency === 'USD' &&
            [
              'wholesale-shop',
              'special-offers',
              'wholesale-orders',
              'lists',
              'conversations'
            ].includes(route.identifier)) {
            return null;
          }

          const merchantsWithoutPayments = [
            'clv7ughnw000f0tz06yaegn51', // Bloom & Go
            'cls48ydws000w0sx9djiictuo', // Floom Blooms US
            'cm013g6tk000a0ttb1z0c8uxy' // Floom Blooms UK
          ];

          if (
            this.props.merchantStore!.merchant?.id &&
            merchantsWithoutPayments.includes(this.props.merchantStore!.merchant?.id) &&
            route.identifier === 'payments'
          ) {
            return null;
          }

          return (
            <Box key={rootUrl}>
              <Styles.NavLinkParent
                href={rootUrl}
                id={id}
                data-testid={`route-id-${id}`}
                key={rootUrl}
                title={route.name}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => this.onClick(e, route, false, route.name, rootUrl)}
                css={css`
                  ${isDisabled && Styles.NavLinkDisabled}
                  ${UrlService.isFuzzyMatch(rootUrl, location) && Styles.NavLinkActive}
                `}
              >
                <Icon
                  iconName={route.icon}
                  styles={css`
                    ${Styles.Icon}
                    ${isDisabled && Styles.IconDisabled}
                  `}
                />
                {!isCollapsed && (
                  <Styles.NavLinkTextWrapper>
                    <Styles.NavLinkText>
                      {route.name}
                    </Styles.NavLinkText>
                    {!!route.isBeta && (
                      <div className="ml-3 inline-flex items-center rounded-md bg-green-50 px-3 pt-0.5 pb-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Beta
                      </div>
                    )}
                    {!!route.label && (
                      <Styles.NavLinkLabel>
                        {route.label}
                      </Styles.NavLinkLabel>
                    )}
                  </Styles.NavLinkTextWrapper>
                )}
                {!!route.unreadCount && (
                  <Styles.NavLinkUnreadCount>
                    {route.unreadCount}
                  </Styles.NavLinkUnreadCount>
                )}
                {!!route.hasNotifications && (
                  <Box>
                    <Styles.NavLinkNotification
                      type={route.notificationType}
                    />
                  </Box>
                )}
              </Styles.NavLinkParent>

              {this.renderChildItems(flags)}
            </Box>
          );
        }}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  merchantStore: stores.merchantStore
}))(observer(NavLink));
